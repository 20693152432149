





































































































































































































import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

import DcmInput from '@/components/misc/DcmInput.vue'
//@ts-ignore
import { QrInput } from '@dcomm-tech/vue-components'
import ValidatorsList from '@/components/misc/ValidatorList/ValidatorsList.vue'
import { ValidatorRaw } from '@/components/misc/ValidatorList/types'
import StakingCalculator from '@/components/wallet/earn/StakingCalculator.vue'
import ConfirmPage from '@/components/wallet/earn/Delegate/ConfirmPage.vue'
import Big from 'big.js'
import moment from 'moment'

import { BN } from '@dcomm-tech/dcomm-js'
import {
    AmountOutput,
    AuthorityVMConstants,
    UTXO,
    UTXOSet,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import { dcomm, dvm, bintools, infoApi, athChain } from '@/DCOMM'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { bnToBig, calculateStakingReward } from '@/helpers/helper'
import { Defaults, ONEDCM } from '@dcomm-tech/dcomm-js/dist/utils'
import { ValidatorListItem } from '@/store/modules/authority/types'
import NodeSelection from '@/components/wallet/earn/Delegate/NodeSelection.vue'
import CurrencySelect from '@/components/misc/CurrencySelect/CurrencySelect.vue'
import Spinner from '@/components/misc/Spinner.vue'
import DateForm from '@/components/wallet/earn/DateForm.vue'
import { WalletType } from '@/js/wallets/types'

import UtxoSelectForm from '@/components/wallet/earn/UtxoSelectForm.vue'
import Expandable from '@/components/misc/Expandable.vue'
import NodeCard from '@/components/wallet/earn/Delegate/NodeCard.vue'

type valeDF = {
    ends?: any
    starts?: any
}

const MIN_MS = 60000
const HOUR_MS = MIN_MS * 60
const DAY_MS = HOUR_MS * 24

@Component({
    components: {
        NodeCard,
        UtxoSelectForm,
        DateForm,
        Spinner,
        CurrencySelect,
        NodeSelection,
        DcmInput,
        ValidatorsList,
        StakingCalculator,
        QrInput,
        ConfirmPage,
        Expandable,
    },
})
export default class AddDelegator extends Vue {
    search: string = ''
    selected: ValidatorListItem | null = null
    stakeAmt: BN = new BN(0)
    startDate: string = new Date(Date.now()).toISOString()
    endDate: string = new Date().toISOString()
    rewardIn: string = ''
    rewardDestination = 'local' // local || custom
    err: string = ''
    isLoading = false
    isConfirm = false
    isSuccess = false
    txId = ''
    txStatus = ''
    txReason: null | string = null

    formNodeID = ''
    formUtxos: UTXO[] = []
    formAmt = new BN(0)
    formEnd: Date = new Date()
    tempEnd: Date = new Date()
    formRewardAddr = ''

    currency_type = 'DCM'

    mounted() {
        this.rewardSelect('local')
    }
    setEnd(val: valeDF) {
        this.endDate = val.ends
    }

    onselect(val: ValidatorListItem) {
        this.search = ''
        this.selected = val
    }

    async submit() {
        if (!this.formCheck()) {
            return
        }

        this.isLoading = true
        this.err = ''

        let wallet: WalletType = this.$store.state.activeWallet

        // Start delegation in 5 minutes
        let startDate = new Date(Date.now() + 5 * MIN_MS)
        let entryDate = new Date(this.startDate)
        let endMs = this.formEnd.getTime() + startDate.getTime() - entryDate.getTime()
        let validatorEndtime: any = this.endMaxDate
        validatorEndtime = new Date(validatorEndtime).getTime()
        if (endMs > validatorEndtime) {
            endMs = validatorEndtime
        }
        let newEndD = new Date(endMs)
        try {
            this.isLoading = false
            let txId = await wallet.delegate(
                this.formNodeID,
                this.formAmt,
                startDate,
                newEndD,
                this.formRewardAddr,
                this.formUtxos
            )
            this.isSuccess = true
            this.txId = txId
            this.updateTxStatus(txId)
        } catch (e) {
            this.onerror(e)
            this.isLoading = false
        }
    }

    onsuccess(txId: string) {
        this.$store.dispatch('Notifications/add', {
            type: 'success',
            title: 'Delegator Added',
            message: 'Your tokens are now locked for staking.',
        })

        // Update History
        setTimeout(() => {
            this.$store.dispatch('Assets/updateUTXOs')
            this.$store.dispatch('History/updateTransactionHistory')
            this.$store.dispatch('History/updateEvmTransaction')
        }, 3000)
    }

    async updateTxStatus(txId: string) {
        let res = await athChain.getTxStatus(txId)
        let status
        let reason = null
        if (typeof res === 'string') {
            status = res
        } else {
            status = res.status
            reason = res.reason
        }

        if (!status || status === 'Processing' || status === 'Unknown') {
            setTimeout(() => {
                this.updateTxStatus(txId)
            }, 5000)
        } else {
            this.txStatus = status
            this.txReason = reason

            if (status === 'Committed') {
                this.onsuccess(txId)
            }
        }
    }

    onerror(e: any) {
        console.error(e)
        let msg: string = e.message

        if (msg.includes('startTime')) {
            this.err = this.$t('earn.delegate.errs.start_end') as string
            // this.err = "Start date must be in the future and end date must be after start date."
        } else if (msg.includes('address format')) {
            this.err = this.$t('earn.delegate.errs.invalid_addr') as string
            // this.err = "Invalid address format. Your address must start with \"ATH-\"";
        } else {
            this.err = e.message
        }
        this.$store.dispatch('Notifications/add', {
            type: 'error',
            title: 'Delegation Failed',
            message: 'Failed to delegate tokens.',
        })
    }

    get estimatedReward(): Big {
        let start = new Date(this.startDate)
        let end = new Date(this.endDate)
        let duration = end.getTime() - start.getTime() // in ms

        let currentSupply = this.$store.state.Authority.currentSupply

        let estimation = calculateStakingReward(this.stakeAmt, duration / 1000, currentSupply)
        let res = Big(estimation.toString()).div(Math.pow(10, 9))
        return res
    }

    get estimatedRewardUSD() {
        return this.estimatedReward.times(this.dcmPrice)
    }

    get dcmPrice(): Big {
        return Big(this.$store.state.prices.usd)
    }

    rewardSelect(val: 'local' | 'custom') {
        if (val === 'local') {
            this.rewardIn = this.rewardAddressLocal
        } else {
            this.rewardIn = ''
        }
        this.rewardDestination = val
    }

    get rewardAddressLocal() {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        return wallet.getAuthorityRewardAddress()
    }

    formCheck(): boolean {
        this.err = ''

        if (!this.selected) {
            this.err = this.$t('earn.delegate.errs.no_node') as string
            // this.err = "You must specify a validator."
            return false
        }

        let startTime = new Date(this.startDate).getTime()
        let endTime = new Date(this.endDate).getTime()
        let now = Date.now()
        let diffTime = endTime - startTime
        if (startTime >= now) {
            this.err = this.$t('earn.delegate.errs.start_now') as string
            return false
        }

        // TODO: UPDATE THIS WITH REAL VALUE
        if (diffTime < DAY_MS * 1) {
            this.err = this.$t('earn.delegate.errs.min_dur') as string
            return false
        }

        if (diffTime > DAY_MS * 365) {
            this.err = this.$t('earn.delegate.errs.max_dur') as string
            return false
        }

        let validatorEndtime = this.selected.endTime.getTime()

        if (endTime > validatorEndtime) {
            this.err = this.$t('earn.delegate.errs.val_end') as string
            return false
        }

        // Reward address check
        if (this.rewardDestination != 'local' && !this.rewardIn) {
            this.err = this.$t('earn.delegate.errs.no_addr') as string
            return false
        }

        // Validate reward address
        try {
            bintools.stringToAddress(this.rewardIn)
        } catch (e) {
            this.err = this.$t('earn.delegate.errs.invalid_addr') as string
            // this.err = "Invalid reward address."
            return false
        }

        // Stake amount check
        if (this.stakeAmt.lt(this.minStake)) {
            let big = bnToBig(this.minStake, 9)
            this.err = this.$t('earn.delegate.errs.amt', [big.toLocaleString()]) as string
            return false
        }
        // Stake amount should not greater than balance or available stake.
        if (Number(bnToBig(this.stakeAmt, 9)) > Number(bnToBig(this.maxAmt, 9))) {
            this.err = 'Staking cannot be more than available balance or available stake balance.'
            return false
        }

        return true
    }

    updateFormData() {
        this.formEnd = new Date(this.endDate)
        let startD = new Date(Date.now() + 5 * MIN_MS)
        let entryD = new Date(this.startDate)
        let endMs = new Date(this.endDate).getTime() + startD.getTime() - entryD.getTime()
        let validatorEndtime: any = this.endMaxDate
        validatorEndtime = new Date(validatorEndtime).getTime()
        if (endMs > validatorEndtime) {
            endMs = validatorEndtime
        }
        this.tempEnd = new Date(endMs)
        this.formNodeID = this.selected!.nodeID
        this.formAmt = this.stakeAmt
        this.formRewardAddr = this.rewardIn
    }

    confirm() {
        if (!this.formCheck()) return
        this.updateFormData()
        this.isConfirm = true
    }

    cancelConfirm() {
        this.isConfirm = false
    }

    get canSubmit(): boolean {
        if (this.stakeAmt.isZero()) {
            return false
        }
        return true
    }

    // Maximum end date is end of validator's staking duration
    get endMaxDate(): string | undefined {
        if (!this.selected) return undefined

        return this.selected.endTime.toISOString()
    }

    get stakingDuration(): number {
        let start = new Date(this.startDate)
        let end = new Date(this.endDate)
        let dur = end.getTime() - start.getTime()
        return dur
    }

    get stakingDurationText(): string {
        let dur = this.stakingDuration
        let d = moment.duration(dur, 'milliseconds')
        // return d.humanize()
        let days = Math.floor(d.asDays())
        return `${days} days ${d.hours()} hours ${d.minutes()} minutes`
    }

    get minStake(): BN {
        return this.$store.state.Authority.minStakeDelegation
    }

    get delegationFee(): number {
        if (!this.selected) return 0
        return this.selected.fee
    }

    get totalFee(): BN {
        let delegationFee = Big(this.delegationFee).div(Big(100))
        let cut = this.estimatedReward.times(delegationFee)

        let txFee: BN = athChain.getTxFee()
        let cutBN = new BN(cut.times(Math.pow(10, 9)).toFixed(0))
        let totFee = txFee.add(cutBN)
        return totFee
    }

    get totalFeeBig() {
        return bnToBig(this.totalFee, 9)
    }

    get totalFeeUsdBig() {
        return this.totalFeeBig.times(this.dcmPrice)
    }

    get txFee(): BN {
        return athChain.getTxFee()
    }

    get txFeeBig(): Big {
        return bnToBig(this.txFee, 9)
    }

    get feeText(): string {
        let big = this.totalFeeBig
        return big.toLocaleString(0)
    }

    get minAmt(): BN {
        return this.minStake.add(this.txFee)
    }

    get remainingAmt(): BN {
        if (!this.selected) return new BN(0)
        // let totDel: BN = this.$store.getters["Authority/validatorTotalDelegated"](this.selected.nodeID);
        let nodeMaxStake: BN = this.$store.getters['Authority/validatorMaxStake'](this.selected)

        let totDel = this.selected.delegatedStake
        let valAmt = this.selected.validatorStake
        return nodeMaxStake.sub(totDel).sub(valAmt)
    }

    get remainingAmtText() {
        let bn = this.remainingAmt
        return bnToBig(bn, 9).toLocaleString()
    }

    get utxosBalance(): BN {
        return this.formUtxos.reduce((acc, val: UTXO) => {
            let out = val.getOutput() as AmountOutput
            return acc.add(out.getAmount())
        }, new BN(0))
    }

    get utxosBalanceBig(): Big {
        return bnToBig(this.utxosBalance, 9)
    }

    get maxAmt(): BN {
        let zero = new BN(0)

        let totAvailable = this.utxosBalance

        if (zero.gt(totAvailable)) return zero

        if (totAvailable.gt(this.remainingAmt)) return this.remainingAmt

        return totAvailable
    }

    // Go Back to earn
    cancel() {
        this.$emit('cancel')
    }

    // get stakeAmtText() {
    //     let amt = this.stakeAmt
    //     let big = Big(amt.toString()).div(Math.pow(10, 9))
    //
    //     if (big.lte(Big('0.0001'))) {
    //         return big.toLocaleString(9)
    //     }
    //     return big.toLocaleString(2)
    // }
    //
    // get authorityUnlocked(): BN {
    //     return this.$store.getters.walletAuthorityBalance
    // }

    get authorityLockedStakeable(): BN {
        return this.$store.getters['Assets/walletAuthorityBalanceLockedStakeable']
    }
}
