















import { DAY_MS, MINUTE_MS } from '../../../constants'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class DateForm extends Vue {
    localStart = this.startDateMin
    localEnd = this.endDateMin

    @Prop() maxEndDate?: string
    @Prop() minDate?: number

    @Watch('localEnd')
    endChange(val: string) {
        let newobj = {
            ends: val,
            starts: this.localStart,
        }
        this.setEndDate(newobj)
        let endTime = new Date(val).getTime()
        let minDateTime = new Date(this.endDateMin).getTime()
        if (endTime < minDateTime) {
            this.localEnd = this.endDateMin
        }
    }

    mounted() {
        this.localStart = this.startDateMin

        // default end date is 3 weeks
        this.localEnd = this.endDateMax

        // this.setStartDate(this.localStart)
        let obj = {
            ends: this.localEnd,
            starts: this.localStart,
        }
        this.setEndDate(obj)
    }

    setEndDate(val: object) {
        this.$emit('change_end', val)
    }

    maxoutEndDate() {
        this.localEnd = this.endDateMax
    }

    // 15 minutes from now
    // In reality it will be 5 minutes after the form is submitted
    get startDateMin() {
        let now = Date.now()
        // let res = now + MINUTE_MS * 15
        return new Date(now).toISOString()
    }

    // now + 15 minutes + 2 weeks (Min Staking Duration)
    get endDateMin() {
        let start = this.localStart
        let startDate = new Date(start)
        let MIN_STAKE_DURATION = DAY_MS * (this.minDate !== undefined ? this.minDate : 1)
        let end = startDate.getTime() + MIN_STAKE_DURATION
        let endDate = new Date(end)
        return endDate.toISOString()
    }

    // Start date + 1 year, or the prop
    get endDateMax() {
        if (this.maxEndDate) {
            return this.maxEndDate
        }

        let start = this.localStart
        let startDate = new Date(start)

        let end = startDate.getTime() + DAY_MS * 365
        let endDate = new Date(end)
        return endDate.toISOString()
    }
}
