

















































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ValidatorListItem } from '@/store/modules/authority/types'
import { bnToBig } from '@/helpers/helper'
import { DcommNetwork } from '@/js/DcommNetwork'

@Component
export default class NodeCard extends Vue {
    @Prop() node!: ValidatorListItem

    get uptimeText(): string {
        return (this.node.uptime * 100).toFixed(2) + '%'
    }

    get nodeStakeBig() {
        return bnToBig(this.node.validatorStake, 9)
    }

    get delegatedStakeBig() {
        return bnToBig(this.node.delegatedStake, 9)
    }

    get remainingStakeBig() {
        return bnToBig(this.node.remainingStake, 9)
    }

    get totalStakeBig() {
        return bnToBig(this.node.validatorStake.add(this.node.delegatedStake), 9)
    }

    get dcommscanURL() {
        let activeNet: DcommNetwork = this.$store.state.Network.selectedNetwork

        if (activeNet.networkId === 1) {
            return `https://Dcommscan.info/staking/validator/${this.node.nodeID}`
        } else {
            return `https://testnet.Dcommscan.info/staking/validator/${this.node.nodeID}`
        }
    }

    get vscoutURL() {
        return `https://vscout.io/validator/${this.node.nodeID}`
    }
}
