



















import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import { UTXO } from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import { ChainIdType } from '@/constants'
import { BN } from '@dcomm-tech/dcomm-js'
import DcommAsset from '@/js/DcommAsset'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { WalletType } from '@/js/wallets/types'

import { bnToBig } from '@/helpers/helper'
import NumberCounter from '@/components/misc/NumberCounter.vue'

const chainTypes: ChainIdType[] = ['AST', 'ATH', 'ACT']
const chainNames = {
    AST: 'Asset Chain',
    ACT: 'Action Chain',
    ATH: 'Authority Chain',
}

@Component({
    components: {
        NumberCounter,
    },
})
export default class ChainCard extends Vue {
    // @Model('change', { type: String }) readonly chain!: ChainIdType
    @Prop() chain!: ChainIdType
    // @Prop() exclude!: ChainIdType
    @Prop({ default: true }) isSource?: boolean

    onChange(ev: any) {
        let val: ChainIdType = ev.target.value
        this.$emit('change', val)
    }

    get chainNames() {
        return chainNames
    }

    get dcomm_asset(): DcommAsset | null {
        let dcomm = this.$store.getters['Assets/AssetDCOMM']
        return dcomm
    }

    get wallet(): WalletType {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        return wallet
    }

    get authorityUnlocked(): BN {
        return this.$store.getters['Assets/walletAuthorityBalance'].available
    }

    get dvmUnlocked(): BN {
        if (!this.dcomm_asset) return new BN(0)
        return this.dcomm_asset.amount
    }

    get evmUnlocked(): BN {
        let balRaw = this.wallet.ethBalance
        return balRaw.div(new BN(Math.pow(10, 9)))
    }

    get balance() {
        if (this.chain === 'AST') {
            return this.dvmUnlocked
        } else if (this.chain === 'ATH') {
            return this.authorityUnlocked
        } else {
            return this.evmUnlocked
        }
    }

    get balanceBig() {
        return bnToBig(this.balance, 9)
    }
    get balanceText() {
        return this.balanceBig.toLocaleString()
    }

    mounted() {}
}
