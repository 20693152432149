




























import { Vue, Component, Prop, Model } from 'vue-property-decorator'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
// import { LedgerWallet } from '@/js/wallets/LedgerWallet'

@Component
export default class SearchAddress extends Vue {
    @Model('change', { type: String }) readonly selectedAddress!: string | null
    @Prop() wallet!: MnemonicWallet

    address: string = ''
    matchingAddrs: string[] = []

    get addrsX(): string[] {
        return this.wallet.getAllDerivedExternalAddresses()
    }

    get addrsP(): string[] {
        return this.wallet.getAllAddressesP()
    }

    emitChange(val: string | null) {
        this.$emit('change', val)
    }

    clearSelection() {
        this.address = ''
        this.matchingAddrs = []

        this.emitChange(null)
    }

    selectAddress(addr: string) {
        this.emitChange(addr)
    }

    onInput() {
        if (this.address === '') {
            this.matchingAddrs = []
            return
        }

        let pAddrs = this.addrsP.filter((addr) => {
            return addr.includes(this.address)
        })
        let xAddrs = this.addrsX.filter((addr) => {
            return addr.includes(this.address)
        })

        this.matchingAddrs = [...pAddrs.slice(0, 2), ...xAddrs.slice(0, 2)]
    }
}
