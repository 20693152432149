









































import { Vue, Component, Prop } from 'vue-property-decorator'
import { ITransactionData, ITransactionDataProcessed } from '@/store/modules/history/types'
import { AssetsDict, NftFamilyDict } from '@/store/modules/assets/types'
import { bnToBig } from '@/helpers/helper'
import { BN, Buffer } from '@dcomm-tech/dcomm-js'

import StakingTx from '@/components/SidePanels/History/ViewTypes/StakingTx.vue'
import BaseTx from '@/components/SidePanels/History/ViewTypes/BaseTx.vue'
import ImportExport from '@/components/SidePanels/History/ViewTypes/ImportExport.vue'
import moment from 'moment'
import { DcommNetwork } from '@/js/DcommNetwork'
import getMemoFromByteString from '@/services/history/utils'
import Tooltip from '../../misc/Tooltip.vue'
import CopyText from '../../misc/CopyText.vue'

@Component({
    components: {
        StakingTx,
        BaseTx,
        ImportExport,
        Tooltip,
        CopyText,
    },
})
export default class TxRow extends Vue {
    @Prop() index!: number
    @Prop() source!: ITransactionDataProcessed

    get explorerUrl(): string | null {
        let network: DcommNetwork = this.$store.state.Network.selectedNetwork
        let currentNet = this.$store.state.Network.selectedNetwork.name.toLowerCase()
        if (network.explorerSiteUrl) {
            return `${network.explorerSiteUrl}/tx/${this.source.id}/network=${currentNet}`
        }
        return null
    }
    get hasMultisig() {
        return this.source.outputs?.filter((utxo) => utxo.addresses.length > 1).length > 0 || false
    }

    get date() {
        return new Date(this.source.timestamp)
    }
    get type() {
        return this.source.type
    }

    get tx_comp() {
        switch (this.type) {
            case 'export':
            case 'import':
            case 'athvm_export':
            case 'athvm_import':
                return ImportExport
            case 'add_delegator':
            case 'add_validator':
                return StakingTx
            default:
                return BaseTx
        }
    }

    get assets(): AssetsDict {
        return this.$store.state.Assets.assetsDict
    }

    get nftFams(): NftFamilyDict {
        return this.$store.state.Assets.nftFamsDict
    }

    get memo(): string | null {
        const memo = this.source.memo
        return getMemoFromByteString(memo)
    }

    get mom() {
        return moment(this.source.timestamp)
    }
    get dayLabel() {
        return this.mom.format('dddd Do')
    }

    get monthLabel(): string {
        let month = this.mom.format('MMMM')
        return month
    }

    get yearLabel(): string {
        return this.mom.format('Y')
    }
    shortenId(id: string, pad: number) {
        return `${id.substring(0, pad)}...${id.substring(id.length - pad, id.length)} `
    }
}
