
































import { Component, Model, Prop, Vue, Watch } from 'vue-property-decorator'
import Spinner from '@/components/misc/Spinner.vue'
import { UTXO } from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import { ChainIdType } from '@/constants'
import { BN } from '@dcomm-tech/dcomm-js'
import DcommAsset from '@/js/DcommAsset'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import { WalletType } from '@/js/wallets/types'

import { bnToBig } from '@/helpers/helper'
import { TxState } from '@/components/wallet/earn/ChainTransfer/types'

@Component({
    components: {
        Spinner,
    },
})
export default class TxStateCard extends Vue {
    @Prop() state!: TxState
    @Prop() status!: string
    @Prop() reason!: string
    @Prop() txId!: string
    @Prop({ default: true }) isExport?: boolean
}
