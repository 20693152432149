






























import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import ValidatorsList from '@/components/misc/ValidatorList/ValidatorsList.vue'
import { ValidatorListItem } from '@/store/modules/authority/types'

@Component({
    components: {
        ValidatorsList,
    },
})
export default class NodeSelection extends Vue {
    search: string = ''

    openFilters() {
        //@ts-ignore
        this.$refs.val_list.openFilters()
    }

    onselect(val: ValidatorListItem) {
        this.$emit('select', val)
    }
}
