

























import { Component, Vue } from 'vue-property-decorator'
import { KeyPair } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import { dcomm, bintools } from '@/DCOMM'
import createHash from 'create-hash'
import { getPreferredHRP } from '@dcomm-tech/dcomm-js/dist/utils'
import { dvm } from '@/DCOMM'
import { Buffer } from '@dcomm-tech/dcomm-js'
import { digestMessage } from '@/helpers/helper'

@Component
export default class VerifyMessage extends Vue {
    message: string = ''
    addressX: string = ''
    addressP: string = ''
    signature = ''
    error = ''

    submit() {
        this.addressX = ''
        this.addressP = ''
        this.error = ''
        try {
            this.verify()
        } catch (e) {
            this.error = e
        }
    }
    verify() {
        let digest = digestMessage(this.message)
        let digestBuff = Buffer.from(digest.toString('hex'), 'hex')

        let networkId = dcomm.getNetworkID()

        let hrp = getPreferredHRP(networkId)
        let keypair = new KeyPair(hrp, 'AST')

        let signedBuff = bintools.cb58Decode(this.signature)

        let pubKey = keypair.recover(digestBuff, signedBuff)
        const addressBuff = KeyPair.addressFromPublicKey(pubKey)
        this.addressX = bintools.addressToString(hrp, 'AST', addressBuff)
        this.addressP = bintools.addressToString(hrp, 'ATH', addressBuff)
    }

    clear() {
        this.message = ''
        this.signature = ''
        this.addressX = ''
        this.addressP = ''
        this.error = ''
    }

    deactivated() {
        this.clear()
    }

    get canSubmit() {
        if (!this.message || !this.signature) return false

        return true
    }
}
