























import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import KeyRow from '@/components/wallet/manage/KeyRow.vue'
import RememberKey from '@/components/misc/RememberKey.vue'
import { WalletType } from '@/js/wallets/types'

@Component({
    components: {
        KeyRow,
        RememberKey,
    },
})
export default class MyKeys extends Vue {
    selectWallet(wallet: WalletType) {
        this.$store.dispatch('activateWallet', wallet)
        this.$store.dispatch('History/updateTransactionHistory')
        this.$store.dispatch('History/updateEvmTransaction')
    }

    get account() {
        return this.$store.getters['Accounts/account']
    }

    async removeWallet(wallet: WalletType) {
        let msg = this.$t('keys.del_check') as string
        let isConfirm = confirm(msg)

        if (isConfirm) {
            await this.$store.dispatch('Accounts/deleteKey', wallet)
            await this.$store.dispatch('removeWallet', wallet)
            this.$store.dispatch('Notifications/add', {
                title: this.$t('keys.remove_success_title'),
                message: this.$t('keys.remove_success_msg'),
            })
        }
    }

    get inactiveWallets(): WalletType[] {
        let wallets = this.wallets

        let res = wallets.filter((wallet) => {
            if (this.activeWallet === wallet) return false
            return true
        })

        return res
    }

    get wallets(): WalletType[] {
        return this.$store.state.wallets
    }

    get activeWallet(): WalletType {
        return this.$store.state.activeWallet
    }
}
