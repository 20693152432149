






























import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { DelegatorRaw, ValidatorRaw } from '../../misc/ValidatorList/types'
import { BN } from '@dcomm-tech/dcomm-js'
import Big from 'big.js'

@Component
export default class UserRewardRow extends Vue {
    now: number = Date.now()
    intervalID: any = null

    @Prop() staker!: ValidatorRaw | DelegatorRaw

    updateNow() {
        this.now = Date.now()
    }

    created() {
        this.intervalID = setInterval(() => {
            this.updateNow()
        }, 2000)
    }
    destroyed() {
        clearInterval(this.intervalID)
    }
    get startTime() {
        return parseInt(this.staker.startTime) * 1000
    }

    get endtime() {
        return parseInt(this.staker.endTime) * 1000
    }

    get startDate() {
        return new Date(this.startTime)
    }

    get endDate() {
        return new Date(this.endtime)
    }

    get rewardAmt(): BN {
        return new BN(this.staker.potentialReward)
    }

    get stakingAmt(): BN {
        return new BN(this.staker.stakeAmount)
    }

    get rewardBig(): Big {
        return Big(this.rewardAmt.toString()).div(Math.pow(10, 9))
    }

    get stakeBig(): Big {
        return Big(this.stakingAmt.toString()).div(Math.pow(10, 9))
    }

    get percFull(): number {
        let range = this.endtime - this.startTime
        let res = (this.now - this.startTime) / range
        return Math.min(res, 1)
    }
}
