
















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import DcmInput from '@/components/misc/DcmInput.vue'
import { BN } from '@dcomm-tech/dcomm-js'
import Big from 'big.js'
import { bnToBig } from '@/helpers/helper'
import { ChainIdType } from '@/constants'
import { dvm } from '@/DCOMM'
import MnemonicWallet from '@/js/wallets/MnemonicWallet'
import DcommAsset from '@/js/DcommAsset'
import { ChainSwapFormData } from '@/components/wallet/earn/ChainTransfer/types'
import { DcommNetwork } from '@/js/DcommNetwork'

const chainTypes: ChainIdType[] = ['AST', 'ATH', 'ACT']
const chainNames = {
    AST: 'AST Chain',
    ACT: 'ACT Chain',
    ATH: 'ATH Chain',
}

@Component({
    components: {
        DcmInput,
    },
})
export default class Form extends Vue {
    sourceChain: ChainIdType = 'AST'
    targetChain: ChainIdType = 'ATH'
    amt: BN = new BN(0)

    @Prop() balance!: Big
    @Prop() maxAmt!: BN
    @Prop() isConfirm!: boolean

    clear() {
        this.amt = new BN(0)
        this.onChange()
    }

    get chainNames() {
        return chainNames
    }

    get formAmtText() {
        return bnToBig(this.amt, 9).toLocaleString()
    }

    get sourceOptions(): ChainIdType[] {
        if (!this.isEVMSupported) {
            return ['AST', 'ATH']
        }

        let all = [...chainTypes]
        return all
    }

    get destinationOptions(): ChainIdType[] {
        return {
            AST: ['ATH', 'ACT'],
            ATH: ['AST', 'ACT'],
            ACT: ['AST', 'ATH'],
        }[this.sourceChain] as ChainIdType[]
    }

    @Watch('destinationOptions')
    onDestinationsChange() {
        this.targetChain = this.destinationOptions[0]
        this.onChange()
    }

    get wallet() {
        let wallet: MnemonicWallet = this.$store.state.activeWallet
        return wallet
    }

    get isEVMSupported() {
        return this.wallet.ethAddress
    }

    onChangeSource(ev: any) {
        let val: ChainIdType = ev.target.value
        this.sourceChain = val
        this.onChange()
    }

    onChangeDestination(ev: any) {
        let val: ChainIdType = ev.target.value
        this.targetChain = val
        this.onChange()
    }

    onAmtChange() {
        this.onChange()
    }

    onChange() {
        let data: ChainSwapFormData = {
            sourceChain: this.sourceChain,
            destinationChain: this.targetChain,
            amount: this.amt,
        }
        this.$emit('change', data)
    }

    mounted() {
        this.onChange()
    }
}
